import { AbstractModel } from './abstract-model';
import Article from './article';
import BaseArticleGroup from './base-article-group';
import BaseArticlePrice from './base-article-price';
import Chest from './chest';
import CoverSheet from './cover-sheet';
import Packaging from './packaging';
import Size from './size';
import Type from './type';

export default class BaseArticle extends AbstractModel {

    name()
    {
        let name = [];

        if (this.article) {
            return this.article.name;
        }

        if (this.packaging) {
            name.push(this.packaging.name);
        }

        if (this.type) {
            name.push(this.type.translate('name'));
        }

        if (this.size) {
            name.push(this.size.translate('name'));
        }

        if (this.chest) {
            name.push(this.chest.name);
        }

        if (this.special) {
            name.push(this.special);
        }

        if (this.coverSheet) {
            name.push(this.coverSheet.name);
        }


        return name.join(' ');
    }

    filters() {
        const filters = {};

        for (const filter of ['packagingId', 'typeId', 'sizeId', 'chestId', 'coverSheetId']) {
            if (this[filter]) {
                filters[filter] = this[filter];
            }
        }

        return filters;
    }

    getMutators()
    {
        return {
            updatedAt: 'datetime'
        }
    }

    relations() {
        return {
            baseArticleGroup: BaseArticleGroup,
            chest: Chest,
            size: Size,
            type: Type,
            coverSheet: CoverSheet,
            article: Article,
            packaging: Packaging,
            baseArticlePrices: BaseArticlePrice
        }
    }

    relationPlural()
    {
        return {
            baseArticleGroup: false,
            chest: false,
            size: false,
            type: false,
            coverSheet: false,
            article: false,
            packaging: false,
            baseArticlePrices: true
        }
    }

}
