//import CheckAndCreate from '@/pages/Prices/CheckAndCreate.vue';
import Index from '../pages/Prices/Index.vue';
import Change from '../pages/Prices/Change.vue';
import List from '../pages/Prices/List.vue';
import ListLastSold from '../pages/Prices/ListLastSold.vue';
//import Show from '../pages/Prices/Show.vue';

export default [
    {
        path: '/prices',
        component: Index,
        name: 'prices-index',
        meta: {permissions: ['prices']}
    }, {
        path: '/prices/change',
        component: Change,
        name: 'prices-change',
        meta: {permissions: ['prices']}
    }, {
        path: '/prices/list',
        component: List,
        name: 'prices-list',
        meta: {permissions: ['prices']}
    }, {
        path: '/prices/list-last-sold',
        component: ListLastSold,
        name: 'prices-list-last-sold',
        meta: {permissions: ['prices']}
    }

    /* ,{
        path: '/prices/:id',
        component: Show,
        name: 'prices-show',
        meta: {permissions: ['prices']},
        props: true
    },{
        path: '/prices/create/:id/:cents',
        component: CheckAndCreate,
        name: 'prices-create',
        meta: {permissions: ['prices']},
        props: true
    } */
];
