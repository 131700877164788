import moment from 'moment';
import { collect } from "collect.js";
import settingService from '../../services/http/setting-service';
import unitService from '../../services/http/unit-service';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default function UseBaseArticleFunctions() {

    const excludeBosjesIds = ref([]);

    const setExcludeBosjesIds = function(ids) {
        excludeBosjesIds.value = ids;
    }

    const articleFallsInBaseArticle = function(article, baseArticle, baseArticleGroupProp = null) {
        const baseArticleGroup = (baseArticle.baseArticleGroup ? baseArticle.baseArticleGroup : baseArticleGroupProp);
        return (
            (baseArticle.articleId && article.hasArticleInBom(baseArticle.articleId))

            || ! baseArticle.articleId && (
                (
                    baseArticleGroup.name === 'Bosjes'
                    && (! baseArticle.typeId || baseArticle.typeId === article.typeId)
                    && (! baseArticle.sizeId || baseArticle.sizeId === article.sizeId)
                    && article.packagingId !== 3
                    && ! excludeBosjesIds.value.contains(id => article.hasArticleInBom(id))
                )

                || (
                    baseArticleGroup.name === 'Radijs per Kilo'
                    && article.packagingId === 3
                    && (! baseArticle.sizeId || baseArticle.sizeId === article.sizeId)
                    && (! baseArticle.typeId || baseArticle.typeId === article.typeId)
                )

                || (
                    baseArticleGroup.name === 'Fust'
                    && (! baseArticle.chestId || baseArticle.chestId === (article.articleChestId ? article.articleChestId : article.chestId))
                )

                || (
                    baseArticleGroup.name === 'Bosjes Verpakkingen'
                    && (! baseArticle.typeId || baseArticle.typeId === article.typeId)
                    && (! baseArticle.packagingId || baseArticle.packagingId === article.packagingId)
                )

                || (
                    baseArticleGroup.name === 'Kilo Verpakkingen'
                    && article.packagingId === 3
                )

                || (
                    baseArticleGroup.name === 'Dekvellen'
                    && (! baseArticle.coverSheetId || baseArticle.coverSheetId === article.coverSheetId)
                )
            )
        )
    }

    const price = function(article, prices, allBaseArticles, baseArticleGroups = null) {
        const baseArticles = allBaseArticles.whereIn('id', article.baseArticleIds);

        let price = 0;
        for (const baseArticle of baseArticles) {
            const baseArticleGroup = baseArticleGroups
                ? baseArticleGroups.firstWhere('id', baseArticle.baseArticleGroupId)
                : baseArticle.baseArticleGroup;

            if (baseArticleGroup.name === 'Bosjes' || baseArticleGroup.name === 'Bosjes Verpakkingen') {
                const content = article.articleContentId ? article.articleContentId : article.contentId;
                price += (
                    (content ? content : 1)
                    * prices.firstWhere('id', baseArticle.id).cents
                );
            } else if (baseArticleGroup.name === 'Radijs per Kilo') {
                const grams = getAmountOfGrams(article);
                price += ((grams/1000) * prices.firstWhere('id', baseArticle.id).cents);
            } else if (baseArticleGroup.name === 'Kilo Verpakkingen'){
                const amount = getAmountOfPackagings(article, baseArticle.articleId);
                price += ((amount ? amount : 1) * prices.firstWhere('id', baseArticle.id).cents);
            } else {
                price += prices.firstWhere('id', baseArticle.id).cents;
            }
        }

        return price;
    }


    const getAmountOfGrams = function(article, amount = 1, total = 0)
    {
        for(const childArticle of article.childArticlesTree )
        {
            if (units.value.firstWhere('id', childArticle.unitId).name === 'Gram') {
                total += getAmountOfGrams(childArticle, 1, amount*childArticle.pivot.amount);
            } else if (childArticle.childArticlesTree.count() > 0) {
                return getAmountOfGrams(childArticle, childArticle.pivot.amount*amount);
            }
        }

        return total;
    }

    const getAmountOfPackagings = function(article, articleId, amount = 1)
    {
        if (article.id === articleId) {
            return amount;
        }

        if (article.childArticlesTree.count()) {
            for (const childArticle of article.childArticlesTree) {
                if (childArticle.hasArticleInBom(articleId)) {
                    return getAmountOfPackagings(childArticle, articleId, amount*childArticle.pivot.amount);
                }
            }
        }

        return 0;
    }

    const units = ref(collect());
    const getUnits = function() {
        return unitService.get().then(unitModels => {
            units.value = unitModels;
        });
    }

    getUnits();

    return {

        // Variables

        // Computed

        // Functions
        articleFallsInBaseArticle, setExcludeBosjesIds, price
    }
}
