<template>
    <div class="container">

        <!-- HEADER & FILTERS -->
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Prijslijst</h1>
                </div>
            </div>
        </div>

        <table style="width: 100%">
            <tr>
                <th><b>Product</b></th>
                <th><b>Fust</b></th>
                <th><b>Inhoud</b></th>
                <th><b>Prijs</b></th>
            </tr>
            <tr v-for="row in rows">
                <td>{{ row.product }}</td>
                <td>{{ row.chest }}</td>
                <td>{{ row.content }}</td>
                <td>{{ formatNumberAsMoney(row.price/100) }}</td>
            </tr>
        </table>

    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { useAuthStore } from '@/stores/auth';
    import baseArticleService from '@/services/http/base-article-service';
    import articleService from '@/services/http/article-service';
    import { useCcstStore } from '@/stores/ccst';
    import UseBaseArticleFunctions from './UseBaseArticleFunctions';
    import UsePriceFunctions from './UsePriceFunctions';
    import collect from 'collect.js';
    import chestService from '@/services/http/chest-service';
    import contentService from '@/services/http/content-service';
    import coverSheetService from '@/services/http/cover-sheet-service';

    const emit = defineEmits(['breadcrumbs']);

    const authUser = computed(() => useAuthStore().user);
    const { articleFallsInBaseArticle, setExcludeBosjesIds } = UseBaseArticleFunctions()
    const { formatNumberAsMoney } = UsePriceFunctions()

    const baseArticles = ref(collect())
    const articles = ref(collect())
    const contents = ref(collect())
    const coverSheets = ref(collect())



    const getModels = function (){
        Promise.all([

            baseArticleService.get({
                include: ['chest', 'size', 'type', 'coverSheet', 'article', 'packaging', 'baseArticleGroup']
            }),
            articleService.get({filters: ['sellable'], include: ['childArticlesTree']}),
            contentService.get(),
            coverSheetService.get()
        ]).then((response) => {
            baseArticles.value = response[0]
            articles.value = response[1];
            contents.value = response[2];
            coverSheets.value = response[3];
            setExcludeBosjesIds(baseArticles.value.where('baseArticleGroup.name', 'Bosjes').pluck('articleId').filter())
            setArticleIdsOnBaseArticle();
            setRows();
        })
    }
    getModels();

    const setArticleIdsOnBaseArticle = function() {
        baseArticles.value.each(baseArticle => {
            baseArticle.articleIds = articles.value.filter(article =>
                articleFallsInBaseArticle(article, baseArticle)
            ).pluck('id');
        })
    }

    const rows = ref([])
    const setRows = function() {
        for (const baseArticle of baseArticles.value.where('baseArticleGroup.name', 'Bosjes')) {
            const baseArticleArticles = articles.value.filter(a => baseArticle.articleIds.contains(a.id));
            for (const festival of [false, true]) {
                for (const content of contents.value) {
                    const contentArticles = baseArticleArticles.where('contentId', content.id)

                    for (const baseArticle2 of baseArticles.value.where('baseArticleGroup.name', 'Fust')) {
                        const baseArticle2Articles = contentArticles.where('chestId', baseArticle2.chestId)
                        for (const baseArticle3 of [null, ...baseArticles.value.where('baseArticleGroup.name', 'Dekvellen')]) {
                            if (baseArticle2Articles.count() > 0 && hasArticles(baseArticle, baseArticle2, {contentId: content.id, coverSheetId: (baseArticle3 ? baseArticle3.coverSheetId : null)}, festival)) {

                                rows.value.push({
                                    product: (festival ? 'flowpack ' : '') + baseArticle.name() + (baseArticle3 ? ' ' + baseArticle3.name() : ''),
                                    chest: baseArticle2.name(),
                                    content: content.name,
                                    price: (baseArticle.cents*content.id) + baseArticle2.cents + (baseArticle3 ? baseArticle3.cents : 0)
                                })
                            }
                        }
                    }
                }
            }

        }
    }


    const hasArticles = function(baseArticle, baseArticle2, otherFilters, festival) {
        const articleIds = baseArticle.articleIds.intersect(baseArticle2.articleIds);
        if (! articleIds) return false;

        let intersectedArticles = articles.value.filter(a => articleIds.contains(a.id));

        if (festival) {
            intersectedArticles = intersectedArticles.filter(a => a.packagingId !== null)
        } else {
            intersectedArticles = intersectedArticles.filter(a => a.packagingId === null)
        }

        return intersectedArticles.contains(article =>
            Object.keys(otherFilters).every(filterKey => article[filterKey] === otherFilters[filterKey])
        );
    }

    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Prijslijst'},
    ]);
</script>
