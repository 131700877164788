<template>
    <div id="prices">
        <div class="container">

            <!-- HEADER & FILTERS -->
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Prijzen</h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <input class="styled-input" type="text" placeholder="Zoeken" v-model="search">
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <button @click="confirmModal = true" class="button success">
                        Opslaan
                    </button>
                </div>
            </div>

            <!-- ARTICLES OVERVIEW -->
            <div class="prices" v-for="baseArticleGroup in baseArticleGroups">
                <div class="article-group">
                    <h2 class="article-group-header">{{ baseArticleGroup.name }}</h2>
                    <table class="styled-table">
                        <thead class="styled-table-header">
                            <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Nieuwe Prijs
                                </th>
                                <th>
                                    Huidige prijs
                                </th>
                                <th>
                                    Laatste wijziging
                                </th>
                                <th>
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="styled-table-row article"
                                v-for="baseArticle of baseArticleGroup.baseArticles.filter(ba => (
                                    ! search
                                    || ba.name().toLowerCase().includes(search.toLowerCase())
                                ))"
                            >
                                <td style="text-align: left;">
                                    {{ baseArticle.name() }}
                                </td>
                                <td>

                                    <label class="new-price-input">
                                        <input
                                            type="number" min="1" step="1"
                                            onkeydown="if(['.', ',','-','+','e','E'].includes(event.key)){event.preventDefault();}"
                                            v-model="baseArticle.cents"
                                            class="styled-input"
                                            @input="(e) => priceChanged(e, baseArticle.id)"
                                        >
                                        <div class="new-price-presenter" style="top: 22px">
                                            {{ formatNumberAsMoney(baseArticle.cents/100) }}
                                        </div>
                                    </label>
                                </td>
                                <td>
                                    {{ formatNumberAsMoney(originalBaseArticles.firstWhere('id', baseArticle.id).cents/100) }}
                                </td>
                                <td>
                                    {{ baseArticle.updatedAt.format('DD-MM-YYYY HH:mm') }}
                                </td>
                                <td class="styled-table-row-icons" style="height:87px">
                                    <a v-tooltip="'Heeft invloed op'" @click="inspectArticleGroupModal = originalBaseArticles.firstWhere('id', baseArticle.id)" class="styled-link">
                                        <Icon name="list-bullet" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <button @click="confirmModal = true" class="button success">
                        Opslaan
                    </button>
                </div>
            </div>
        </div>
    </div>

    <Modal
        class="modal-lg"
        :excludeSaveButton="true"
        @close="inspectArticleGroupModal = null"
        v-if="inspectArticleGroupModal"
    >
        <template #header>Artikelen</template>

        <div>
            <div style="display: flex">
                <div style="flex: 3">Naam</div>
                <div style="flex: 1">Oude prijs</div>
                <div style="flex: 1">Nieuwe prijs</div>
            </div>
            <div v-for="article of inspectArticleGroupModal.articles" style="display: flex">
                <div style="flex: 3">{{ article.name }}</div>
                <div style="flex: 1">
                    {{ formatNumberAsMoney(article.cents/100) }}
                </div>
                <div style="flex: 1">
                    {{ formatNumberAsMoney(getPrice(article)/100) }}
                </div>
            </div>
        </div>
    </Modal>

    <Modal class="modal-lg" v-if="confirmModal" @close="confirmModal = false" @save="save">
        <template #header>Bevestigen</template>

        <div>
            Weet je zeker dat je de onderstaande prijswijzigingen wilt doorvoeren?

            <div >
                <div style="display: flex">
                    <div style="flex: 3">Naam</div>
                    <div style="flex: 1">Oude prijs</div>
                    <div style="flex: 1">Nieuwe prijs</div>
                </div>
                <div style="display: flex" v-for="article of changedArticles">
                    <div style="flex: 3">{{ article.name }}</div>
                    <div style="flex: 1">{{ formatNumberAsMoney(article.cents/100) }}</div>
                    <div style="flex: 1">{{ formatNumberAsMoney(getPrice(article)/100) }}</div>

                </div>
            </div>
        </div>
    </Modal>

    <LoaderWithOverlay v-if="loading"></LoaderWithOverlay>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { useToast } from 'vue-toast-notification';
    import articleService from '@/services/http/article-service';
    import collect from 'collect.js';
    import UsePriceFunctions from './UsePriceFunctions';
    import UseBaseArticleFunctions from './UseBaseArticleFunctions';
    import baseArticleGroupService from '@/services/http/base-article-group-service';
    import baseArticleService from '@/services/http/base-article-service';
    import unitService from '@/services/http/unit-service';
    import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue';

    const emit = defineEmits(['breadcrumbs']);

    const { formatNumberAsMoney } = UsePriceFunctions()
    const { articleFallsInBaseArticle, setExcludeBosjesIds, price } = UseBaseArticleFunctions()

    const search = ref('');

    const prices = ref(collect());
    const baseArticleGroups = ref(collect());
    const originalBaseArticles = ref(collect());


    const articles = ref(collect());

    const inspectArticleGroupModal = ref(null);

    const confirmModal = ref(false);
    const loading = ref(false);


    /*
    |--------------------------------------------------------------------------
    | Store
    |--------------------------------------------------------------------------
    */
    const save = function() {
        loading.value = true;
        return baseArticleService.updatePrices(changedPrices.value.all()).then(() => {
            getModels().then(
                () => {
                    loading.value = false;
                    useToast().success('Prijzen opgeslagen');
                    confirmModal.value = false;
                }
            ).catch(() => {
                loading.value = false;
                useToast().error('Er is iets fout gegaan');
            })
        });
    }

    /*
    |--------------------------------------------------------------------------
    | parseData
    |--------------------------------------------------------------------------
    */
    const priceChanged = function(e, id) {
        const value = e.currentTarget.value;
        const price = prices.value.firstWhere('id', id);
        price.cents = parseInt(value ? value : 0);
    }

    const changedArticles = computed(() => {
        return articles.value.filter(a => {
            return a.cents !== getPrice(a)
        });
    })

    const changedPrices = computed(() =>
        prices.value.filter(price => originalBaseArticles.value.firstWhere('id', price.id).cents !== price.cents)
    )

    const parseData = function() {
        setExcludeBosjesIds(baseArticleGroups.value.firstWhere('name', 'Bosjes').baseArticles.pluck('articleId').filter());
        for (const originalBaseArticle of originalBaseArticles.value) {
            const baseArticleGroup = baseArticleGroups.value.firstWhere('id', originalBaseArticle.baseArticleGroupId)

            originalBaseArticle.articles = articles.value.filter(article => {
                return articleFallsInBaseArticle(article, originalBaseArticle, baseArticleGroup);
            });

            originalBaseArticle.articles.each(a => {
                a.baseArticleIds.push(originalBaseArticle.id)
            });
        }
    }

    const getPrice = function(cents) {
        return price(cents, prices.value, originalBaseArticles.value, baseArticleGroups.value);
    }

    /*
    |--------------------------------------------------------------------------
    | Getters
    |--------------------------------------------------------------------------
    */

    const getModels = function() {
        return Promise.all([getArticles(), getBaseArticleGroups()]).then(() => {
            parseData();
        })
    }

    const getArticles = function() {
        return articleService.get({'include': ['childArticlesTree']}).then(articleModels => {
            articles.value = articleModels.each(a => a.baseArticleIds = []);
        });
    }


    const getBaseArticleGroups = function() {
        return baseArticleGroupService.get({
            include: [
                'baseArticles.chest', 'baseArticles.size', 'baseArticles.type',
                'baseArticles.coverSheet', 'baseArticles.packaging', 'baseArticles.article',
            ]
        }).then(baseArticleGroupModels => {
            baseArticleGroups.value = baseArticleGroupModels;

            originalBaseArticles.value = collect(
                JSON.parse(JSON.stringify(baseArticleGroupModels.pluck('baseArticles').flatten(1).all()))
            );

            prices.value = collect(
                JSON.parse(JSON.stringify(baseArticleGroupModels.pluck('baseArticles').flatten(1).all()))
            ).map(baseArticle => ({id: baseArticle.id, cents: baseArticle.cents}));
        });
    }

    getModels();


    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Wijzig'},
    ]);

</script>
