<template>
    <div class="container">

        <!-- HEADER & FILTERS -->
        <div class="row">
            <div class="col">
                <div class="page-header" style="position: relative">
                    <h1>Recent verkocht prijslijst</h1>
                    <h5 style="position: absolute; left: 0px; top: 50px">Laatste maand</h5>

                </div>
            </div>
        </div>

        <table>
            <thead>
                <tr>
                    <td><b>Naam</b></td>
                    <td><b>Prijs</b></td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="article of articles">
                    <td>{{ article.name }}</td>
                    <td>{{ formatNumberAsMoney(price(
                        article,
                        baseArticles,
                        baseArticles
                    )/100) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue';
    import { useAuthStore } from '@/stores/auth';
    import articleService from '@/services/http/article-service';
    import moment from 'moment';
    import UseBaseArticleFunctions from './UseBaseArticleFunctions';
    import UsePriceFunctions from './UsePriceFunctions';
    import collect from 'collect.js';
    import baseArticleService from '@/services/http/base-article-service';

    const emit = defineEmits(['breadcrumbs']);

    const authUser = computed(() => useAuthStore().user);
    const { articleFallsInBaseArticle, setExcludeBosjesIds, price } = UseBaseArticleFunctions()
    const { formatNumberAsMoney } = UsePriceFunctions()

    const articles = ref(collect());
    const baseArticles = ref(collect());

    const getModels = function() {
        Promise.all([
            articleService.get({
                filters:{
                    betweens: [{
                        from: moment().subtract(1, 'month').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                        till: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
                    }]
                },
                include: ['childArticlesTree']
            }),
            baseArticleService.get({include: ['baseArticleGroup']})
        ]).then(data => {
            articles.value = data[0];
            baseArticles.value = data[1];
            setExcludeBosjesIds(baseArticles.value.where('baseArticleGroup.name', 'Bosjes'))

            for (const article of articles.value) {
                article.baseArticles = baseArticles.value.filter(ba => {
                    return articleFallsInBaseArticle(article, ba);
                });
                article.baseArticleIds = article.baseArticles.pluck('id');
            }
        });
    };
    getModels();


    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Prijslijst recent verkocht'},
    ]);
</script>
