<template>
    <div class="container">

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('prices')" @click="$router.push('/prices/change')">
                <Icon name="light-bulb" />
                <span>Prijzen</span>
            </a>
        </div>

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('prices')" @click="$router.push('/prices/list')">
                <Icon name="funnel" />
                <span>Prijslijst</span>
            </a>
        </div>

        <div class="settings-overview">
            <a class="settings-button" v-if="authUser.can('prices')" @click="$router.push('/prices/list-last-sold')">
                <Icon name="calculator" />
                <span>Prijslijst recent verkocht</span>
            </a>
        </div>

    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useAuthStore } from '@/stores/auth';

    const authUser = computed(() => useAuthStore().user);
</script>
