import Unit from "../../models/unit";
import { AbstractHttpService } from "./abstract-http-service";

class UnitService extends AbstractHttpService {

    endpoint() {
        return 'units';
    }

    createModel(data) {
        return new Unit(data);
    }
}

export default new UnitService;
